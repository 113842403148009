<template>
  <div>
    <b-card>
      <AgGrid
        ref="ContainerTrackingGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      >
        <template v-slot:lftbtn>
          <b-button
            v-if="(Role == 'company admin') || (Role == 'manager') || (Role == 'user')"
            v-b-tooltip.hover="'Upload Container Tracking Data'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="UploadContainerTracking()"
          ><font-awesome-icon
             :icon="['far', 'plus-square']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Upload</span></b-button>
        </template>

      </AgGrid>
    </b-card>

    <b-modal
      id="UploadContainerTrackingDataModal"
      ref="UploadContainerTrackingData"
      title="Upload Container Tracking Data"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="headerRules"
        tag="form"
      >
        <b-row>
          <b-col md="12">
            <span style="color:red">* Please note only csv (.csv) can be processed.</span>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Doc"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <b-form-file
                    ref="file"
                    v-model="Doc"
                    accept=".csv"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>

          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="SaveContainerData()"
          >
            <span class="align-middle">Upload</span></b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from '@axios'

import { required } from '@validations'
import AgGrid from '@/layouts/components/AgGrid.vue'

export default {
  components: {
    AgGrid,
  },
  data() {
    return {
      required,
      aggrid: [],
      Permission: {},
      Role: '',
      Doc: [],
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.BookingDbContext
    this.aggrid = this.InitGridInstance('ContainerTracking')
    this.aggrid.columnDefs = [
      {
        headerName: 'Container #', field: 'container', minWidth: 125, enableRowGroup: false, enablePivot: false,
      },
      {
        headerName: 'Customer', field: 'customer', minWidth: 125, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Shipper', field: 'shipper', minWidth: 125,
      },
      {
        headerName: 'MBL', field: 'mbl', minWidth: 120,
      },
      {
        headerName: 'HBL', field: 'hbl', minWidth: 120,
      },
      {
        headerName: 'Mode', field: 'job_Mode', minWidth: 75, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Carrier', field: 'carrier', minWidth: 15, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'F/V',
        marryChildren: true,
        children: [
          {
            headerName: 'Vessel',
            field: 'feeder_Vessel',
            autoHeight: true,
            wrapText: true,
            minWidth: 220,
            enableRowGroup: false,
            enablePivot: false,
            cellRenderer: 'agGroupCellRenderer',
          },
          {
            headerName: 'POL', field: 'poL_Feeder_Vessel', minWidth: 75, filter: 'agSetColumnFilter', columnGroupShow: 'open',
          },
          {
            headerName: 'POD', field: 'poD_Feeder_Vessel', minWidth: 75, filter: 'agSetColumnFilter', columnGroupShow: 'open',
          },
          {
            headerName: 'ETD',
            field: 'etD_Feeder_Vessel',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            columnGroupShow: 'open',
          },
          {
            headerName: 'ATD',
            field: 'atD_Feeder_Vessel',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            columnGroupShow: 'open',
          },
          {
            headerName: 'ETA',
            field: 'etA_Feeder_Vessel',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            columnGroupShow: 'open',
          },
          {
            headerName: 'ATA',
            field: 'atA_Feeder_Vessel',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            columnGroupShow: 'open',
          },
        ],
      },
      {
        headerName: 'M/V',
        marryChildren: true,
        children: [
          {
            headerName: 'Vessel',
            field: 'mother_Vessel',
            autoHeight: true,
            wrapText: true,
            minWidth: 220,
            enableRowGroup: false,
            enablePivot: false,
            cellRenderer: 'agGroupCellRenderer',
          },
          {
            headerName: 'POL', field: 'poL_Mother_Vessel', minWidth: 75, filter: 'agSetColumnFilter', columnGroupShow: 'open',
          },
          {
            headerName: 'POD', field: 'poD_Mother_Vessel', minWidth: 75, filter: 'agSetColumnFilter', columnGroupShow: 'open',
          },
          {
            headerName: 'ETD',
            field: 'etD_Mother_Vessel',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            columnGroupShow: 'open',
          },
          {
            headerName: 'ATD',
            field: 'atD_Mother_Vessel',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            columnGroupShow: 'open',
          },
          {
            headerName: 'ETA',
            field: 'etA_Mother_Vessel',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            columnGroupShow: 'open',
          },
          {
            headerName: 'ATA',
            field: 'atA_Mother_Vessel',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            columnGroupShow: 'open',
          },
        ],
      },
      {
        headerName: 'Terminal',
        marryChildren: true,
        children: [
          {
            headerName: 'Terminal',
            field: 'terminals',
            autoHeight: true,
            wrapText: true,
            minWidth: 220,
            enableRowGroup: false,
            enablePivot: false,
            cellRenderer: 'agGroupCellRenderer',
          },
          {
            headerName: 'Terminal LFD',
            field: 'terminal_LFD',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            columnGroupShow: 'open',
          },
          {
            headerName: 'Pickup Appt',
            field: 'terminal_Pickup_Appt',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            columnGroupShow: 'open',
          },
        ],
      },
      {
        headerName: 'Milestones',
        marryChildren: true,
        children: [
          {
            headerName: 'Booking ETA',
            field: 'booking_ETA',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'F/V ETD',
            field: 'etD_Feeder_Vessel',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'F/V ATD',
            field: 'atD_Feeder_Vessel',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            columnGroupShow: 'open',
          },
          {
            headerName: 'F/V ETA',
            field: 'etA_Feeder_Vessel',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'F/V ATA',
            field: 'atA_Feeder_Vessel',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            columnGroupShow: 'open',
          },
          {
            headerName: 'M/V ETD',
            field: 'etD_Mother_Vessel',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'M/V ATD',
            field: 'atD_Mother_Vessel',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            columnGroupShow: 'open',
          },
          {
            headerName: 'M/V ETA',
            field: 'etA_Mother_Vessel',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'M/V ATA',
            field: 'atA_Mother_Vessel',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            columnGroupShow: 'open',
          },
          {
            headerName: 'Berthing Date',
            field: 'berthing_Date',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            columnGroupShow: 'open',
          },
          {
            headerName: 'ETA Fianl Ramp/CFS',
            field: 'etA_to_Final_Ramp_CFS',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            columnGroupShow: 'open',
          },
          {
            headerName: 'ATA Fianl Ramp/CFS',
            field: 'atA_to_Final_Ramp_CFS',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            columnGroupShow: 'open',
          },
          {
            headerName: 'Arrival Notice',
            field: 'a_N_Sent',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            columnGroupShow: 'open',
          },
          {
            headerName: 'Customs Release', field: 'customs_Release', minWidth: 120,
          },
          {
            headerName: 'Freight Release', field: 'freight_Release', minWidth: 120,
          },
          {
            headerName: 'Out Gate',
            field: 'out_Gate_POD_RAMP',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'On Rail',
            field: 'on_Rail',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            columnGroupShow: 'open',
          },
          {
            headerName: 'Off Rail',
            field: 'off_Rail',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            columnGroupShow: 'open',
          },
          {
            headerName: 'DO Date',
            field: 'do_Created',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            columnGroupShow: 'open',
          },
          {
            headerName: 'Trucker Dispatched',
            field: 'trucker_dispatched',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'Delivery',
            field: 'delivery_to_customer_Date',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'Empty Return',
            field: 'empty_Return',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
        ],
      },
      {
        headerName: 'Remarks', field: 'remarks', minWidth: 75,
      },
      {
        headerName: 'Edit Log',
        marryChildren: true,
        children: [
          {
            headerName: 'Handling Office', field: 'handling_Office', minWidth: 125, filter: 'agSetColumnFilter', columnGroupShow: 'open',
          },
          {
            headerName: 'CS Name', field: 'cS_Name', minWidth: 75, filter: 'agSetColumnFilter', columnGroupShow: 'open',
          },
          {
            headerName: 'Updated On',
            field: 'lastUpdated',
            maxWidth: 158,
            minWidth: 100,
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            enableRowGroup: false,
            enablePivot: false,
          },
        ],
      },
    ]

    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.ContainerTrackingGrid.saveState('ContainerTracking') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.ContainerTrackingGrid.saveState('ContainerTracking') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.ContainerTrackingGrid.saveState('ContainerTracking') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.ContainerTrackingGrid.saveState('ContainerTracking') }

    this.LoadAll()
  },
  methods: {
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadContainerList()])
        .then(() => {
          // Get user role
          this.Role = this.GetUserRole()
          // Get permission
          this.Permission = this.GetUserPermission('Booking')
          this.LoadingEnd()
        })
    },
    LoadContainerList() {
      axios.get('/booking/container/tracking/active')
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.ContainerTrackingGrid.ResetColumns()
        })
    },
    UploadContainerTracking() {
      this.$refs.UploadContainerTrackingData.show()
    },
    SaveContainerData() {
      this.$refs.headerRules.validate().then(result => {
        if (result) {
          this.LoadingStart()

          const formData = new FormData()
          formData.append('file', this.Doc)

          axios.post('/booking/container/tracking/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
            .then(() => {
              this.LoadingEnd()
              this.$refs.UploadContainerTrackingData.hide()
              this.DisplaySuccess('Document has been uploaded.').then(() => {
                this.LoadContainerList()
              })
            })
            .catch(e => {
              this.LoadingEnd()
              this.$refs.UploadContainerTrackingData.hide()
              this.DisplayError('Document cannot be uploaded.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('Cannot upload file.', 'Please check data requirements.')
        }
      })
    },
  },
}
</script>
